.p-list__header {
  background-image: linear-gradient(to left, #2c6c88, #0084b5);
  padding: 30px null;

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__end {
    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 40px;
      color: $white;
      background-color: #31d0aa;
      border-radius: 10px;
      font-size: 14px;
      font-weight: $weight-semibold;
      padding: null 60px;
      line-height: 1;
      margin-top: 15px;
    }
  }

  &__breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    color: $white;
    margin-bottom: 15px;

    a,
    span {
      display: inline-block;
      color: $white;
    }

    i {
      display: inline-block;
      padding: null 7px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    h2 {
      font-size: 26px;
      font-weight: $weight-bold;
      color: $white;
      margin-bottom: 0;
    }
    h3 {
      font-size: 26px;
      font-weight: $weight-light;
      color: $white;
      margin-left: 15px;
      margin-bottom: 0;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: $weight-bold;
    color: $white;
  }
}

.p-list__main {
  padding: 30px null;
}

.p-list__sidebar {
  background-color: $white;
  border-radius: 10px;
  padding-bottom: 15px;

  h4 {
    font-size: 16px;
    font-weight: $weight-bold;
    color: #202020;
    padding: 15px;
    margin-bottom: 0;
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: $weight-light;
        color: #000;
        padding: 10px 15px;
        border-bottom: 1px solid #f3f3f3;
        border-left: 5px solid transparent;

        &:hover {
          background-color: rgba(#10bb92, 0.05);
        }
      }

      &.active {
        a {
          background-color: rgba(#10bb92, 0.09);
          color: #10bb92;
          font-weight: $weight-bold;
          border-left-color: #10bb92;
        }
      }
    }
  }
}

.p-list__content {
  background-color: $white;
  border-radius: 10px;

  h4 {
    font-size: 20px;
    font-weight: $weight-light;
    color: #000;
    padding: 15px;
    margin-bottom: 0;
  }

  table.table {
    margin-bottom: 0;

    thead {
      tr {
        th {
          font-weight: $weight-bold;
          font-size: 14px;
          color: #000;
          border-bottom: 1px solid #efefef !important;
          padding: 10px 15px;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #efefef;
        border-left: 5px solid transparent;

        th {
          font-weight: $weight-light;
          font-size: 14px;
          color: #000;
          padding: 10px 15px;
        }
        td {
          font-weight: $weight-light;
          font-size: 14px;
          color: #000;
          padding: 10px 15px;

          strong {
            display: inline-flex;
            align-items: center;
            font-weight: $weight-light;
            font-size: 14px;
            color: #000;

            .w-icon {
              size: 15px;
              margin-right: 15px;
              flex-shrink: 0;
            }
          }
        }

        &.active {
          background-color: rgba(#10bb92, 0.09);
          border-left-color: #10bb92;

          th,
          td {
            font-weight: $weight-bold;
          }
        }
      }
    }
  }
}

.p-list__pagination {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px null;

  li {
    padding: null 10px;
    a {
      display: inline-block;
      color: #2c6c88;
      font-size: 14px;
      font-weight: $weight-semibold;
      font-weight: $family-sans-serif-2;
    }

    &.active {
      a {
        color: #10bb92;
      }
    }
  }
}
