// Colors
$black: #2d3436 !default;
$white: #ffffff !default;
$color-1: #d63031 !default;
$color-2: #fdcb6e !default;
$color-3: #0984e3 !default;

// Typography
$family-sans-serif: 'Noto Sans', sans-serif !default;
$family-sans-serif-2: 'Montserrat', sans-serif !default;
$family-monospace: monospace !default;
$family-cursive: 'Charm', cursive !default;

$weight-extra-light: 200 !default;
$weight-light: 300 !default;
$weight-regular: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 600 !default;
$weight-bold: 700 !default;
$weight-black: 900 !default;

// Z-index
$z-index-1: 10 !default;
$z-index-2: 20 !default;
$z-index-3: 30 !default;
$z-index-4: 40 !default;
$z-index-5: 50 !default;
$z-index-6: 60 !default;
$z-index-7: 70 !default;
$z-index-8: 80 !default;
$z-index-9: 90 !default;

$z-index-default-header: 4 !default;
$z-index-default-content: 3 !default;
$z-index-default-footer: 2 !default;

// Animation
$transition-easing: ease-out !default;
$transition-speed: 250ms !default;
