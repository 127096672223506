.p-detail__content {
  background-color: $white;
  border-radius: 10px;
}

.p-detail__head {
  margin-bottom: 30px;

  &__start {
    h4 {
      color: #10bb92;
      font-size: 16px;
      font-weight: $weight-bold;
      padding: 15px;
      margin-bottom: 0;
    }

    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #efefef;
        padding: 10px 15px;

        strong {
          display: block;
          min-width: 180px;
          font-size: 14px;
          font-weight: $weight-semibold;
          color: #000;
        }

        span {
          display: block;
          font-size: 14px;
          font-weight: $weight-light;
          color: #000;
        }
      }
    }
  }

  &__end {
    img {
      size: 100%;
      object-fit: cover;
    }
  }
}

.p-detail__block {
  padding: 15px;
  border-bottom: 1px solid #efefef;

  h4 {
    color: #10bb92;
    font-size: 16px;
    font-weight: $weight-bold;
  }

  p {
    font-size: 14px;
    font-weight: $weight-light;
    color: #000;
  }

  form {
    input {
      display: block;
      height: 40px;
      border: 1px solid rgba(#00749e, 0.5);
      border-radius: 10px;
      background-color: $white;
      padding: null 15px;
      width: 100%;
      margin-bottom: 15px;

      &:focus {
        outline-width: 0;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 40px;
      background-color: #31d0aa;
      color: $white;
      width: 100%;
      border-width: 0;
      font-size: 14px;
      font-weight: $weight-semibold;
      border-radius: 40px;
    }
  }
}
