.c-side-navigation {
  position: fixed;
  top: 0;
  left: 0;
  size: 100%;
  z-index: $z-index-2;
  background-color: rgba($black, 0.75);
  display: none;

  &__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    size: 320px 100%;
    background-color: $white;
  }

  &__start {
    display: flex;
    flex-direction: column;
    padding: 15px;

    a {
      display: block;
      margin-bottom: 15px;

      img {
        width: 100%;
      }
    }

    form {
      position: relative;

      input {
        border: 1px solid rgba(#00749e, 0.5);
        border-radius: 10px;
        height: 50px;
        padding: null 15px;
        padding-right: 60px;
        font-family: $family-sans-serif-2;
        font-weight: $weight-medium;
        color: #0d5f7d;
        width: 100%;

        &:focus {
          outline-width: 0;
          border-color: darken(#0d5f7d, 10%);
        }
      }

      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: transparent;
        size: 40px;
        border-width: 0;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);

        .w-icon {
          size: 100%;
        }
      }
    }
  }

  &__nav {
    > ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      > li {
        > a,
        > span {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px;
          border-bottom: 1px solid rgba(#0d5f7d, 0.1);
          color: #0d5f7d;
          font-size: 14px;
          font-weight: $weight-semibold;
          cursor: pointer;

          i {
            margin-left: 5px;
          }
        }

        ul {
          display: none;
        }

        &:hover {
          > a {
            background-color: rgba(#2c6c88, 0.1);
          }

          ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            display: block;
            background-color: #2c6c88;

            li {
              a {
                display: block;
                padding: 10px 30px;
                color: $white;
                border-bottom: 1px solid rgba($white, 0.1);
                font-size: 14px;

                &:hover {
                  background-color: rgba($white, 0.1);
                }
              }
            }
          }
        }
      }
    }
  }
}
