::-moz-selection {
  background-color: $selection-background;
}
::selection {
  background-color: $selection-background;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

input,
textarea,
select,
button {
  font-family: $body-font-family;
  font-size: 14px;
  -webkit-tap-highlight-color: transparent;
}

code,
pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: $code-font-family;
}

body {
  @if type-of($body-background) == 'color' {
    background-color: $body-background;
  } @else {
    background-image: $body-background;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  margin: 0;
  font-family: $body-font-family;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: $body-line-height;
  color: $body-text-color;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: $body-text-rendering;
  text-size-adjust: 100%;
}

a {
  color: $link-color;
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    color: $link-color-hover;
  }

  &:active {
    color: $link-color-active;
  }

  strong {
    color: currentColor;
  }
}

hr {
  background-color: $hr-background-color;
  border: none;
  display: block;
  height: $hr-height;
  @include margin($hr-margin);
}

img {
  height: auto;
  max-width: 100%;
}

input[type='checkbox'],
input[type='radio'] {
  vertical-align: baseline;
}

small {
  font-size: 0.875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  font-weight: $strong-weight;
}

fieldset {
  border: none;
}

table {
  td,
  th {
    text-align: left;
    vertical-align: top;
  }
}

.container-fluid {
  @include media('>=medium') {
    padding-right: 100px;
    padding-left: 100px;
  }
}
