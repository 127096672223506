.p-home__main-hero {
  &__item {
    position: relative;
    height: 500px;

    img {
      size: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      object-fit: cover;
    }

    &__body {
      position: relative;
      z-index: 3;
      height: 100%;

      .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        h3 {
          font-size: 40px;
          font-weight: $weight-semibold;
          color: $white;
          margin-bottom: 30px;
        }

        p {
          fon-size: 21px;
          color: $white;
          margin-bottom: 0;
        }
      }
    }
  }
}

.p-home__services {
  background-color: $white;
  padding: 100px null;

  &__item {
    text-align: center;

    .w-icon {
      size: auto 67px;
      margin-bottom: 30px;

      svg {
        size: auto 67px;
        position: static;
      }
    }

    h4 {
      color: #0084b5;
      font-size: 24px;
      font-weight: $weight-semibold;
      margin-bottom: 15px;
    }

    p {
      font-size: 14px;
      font-weight: $weight-light;
      color: #000;
      margin-bottom: 0;
    }
  }
}

.p-home__groups {
  padding: 60px null;

  h3 {
    font-size: 26px;
    font-weight: $weight-bold;
    color: #000;
    text-align: center;
    margin-bottom: 30px;
  }

  &__item {
    text-align: center;

    &__img {
      size: 100% 250px;
      margin-bottom: 15px;
      background-color: rgba(#2c6c88, 0.5);
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      transition: all 500ms;

      img {
        size: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }

      &:hover {
        background-color: transparent;
      }
    }

    h4 {
      color: #000;
      font-size: 16px;
      font-weight: $weight-bold;
      margin-bottom: 0;
    }
  }
}

.p-home__about-us {
  background-color: $white;
  padding: 100px null;

  p {
    font-size: 20px;
    font-weight: $weight-light;
    color: #000;
    margin-bottom: 30px;
    text-align: center;
  }

  a.c-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 40px;
    min-width: 190px;
    background-color: #0084b5;
    color: #fff;
    border-radius: 30px;
    font-size: 14px;
    font-weight: $weight-semibold;
    line-height: 1;
  }
}

.p-home__producers {
  background-color: $white;
  padding: 30px null 60px;

  h3 {
    font-size: 26px;
    font-weight: $weight-bold;
    color: #000;
    text-align: center;
    margin-bottom: 30px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 30px;

    @include media('<xlarge') {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include media('<large') {
      grid-template-columns: 1fr 1fr;
    }
    @include media('<small') {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    background-color: rgba(#eff3f4, 0.5);
    padding: 15px 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
      max-width: 100%;
      filter: grayscale(100%);
      transition: all 500ms;
    }

    &:hover {
      img {
        filter: none;
      }
    }
  }
}
