.w-button {
  display: inline-flex;
  align-items: center;
  color: $color-3;
  border: 1px solid $color-3;
  border-radius: 4px;
  transition: all 500ms;
  line-height: 1;
  @include padding(7px 14px);

  span {
    margin-left: 10px;
  }

  .w-icon {
    @include size(16px);

    svg {
      fill: $color-3;
      transition: all 500ms;
    }
  }

  &:hover {
    background-color: lighten($color-1, 45%);
    color: $color-1;
    border-color: $color-1;

    .w-icon {
      svg {
        fill: $color-1;
        @include second-icon;
      }
    }
  }

  &:active {
    background-color: lighten($color-3, 30%);
    color: $color-3;
    border-color: $color-3;

    .w-icon {
      svg {
        fill: $color-3;
      }
    }
  }
}
