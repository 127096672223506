.c-default-footer {
  &__contact {
    background-color: #2c6c88;
    padding: 30px;

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      @include media('<medium') {
        flex-direction: column;
      }
    }

    p {
      margin-right: 30px;
      margin-bottom: 0;
      font-size: 20px;
      color: $white;

      @include media('<medium') {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    strong {
      display: flex;
      align-items: center;
      color: $white;
      font-size: 20px;
      font-weight: $weight-light;

      .w-icon {
        size: 30px;
        margin-right: 15px;
      }
    }
  }

  &__content {
    background-color: #eff3f4;
    padding: 30px null;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: max-content;
    grid-gap: 30px;
    align-items: flex-start;
    padding-bottom: 30px;
    border-bottom: 1px solid #2c6c88;
    margin-bottom: 30px;

    @include media('<xlarge') {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include media('<large') {
      grid-template-columns: 1fr 1fr;
    }
    @include media('<small') {
      grid-template-columns: 1fr;
    }
  }

  &__logo {
    display: inline-block;

    @include media('<xlarge') {
      grid-column: span 5;
    }
  }

  &__nav {
    @include media('<xlarge') {
      grid-column: span 1;
    }
    @include media('<large') {
      grid-column: span 2;
    }
    @include media('<small') {
      grid-column: span 4;
    }

    h6 {
      font-size: 14px;
      font-weight: $weight-semibold;
      color: #000;
    }

    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        a {
          display: inline-block;
          font-size: 14px;
          font-weight: $weight-light;
          color: #000;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__end {
    color: #2c6c88;
    font-size: 12px;
    text-align: center;
  }
}
