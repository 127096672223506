.c-default-header {
  background-color: $white;
  font-family: $family-sans-serif-2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $z-index-1;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    flex-shrink: 0;

    img {
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    > button {
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      size: 30px;
      background-color: transparent;
      border-width: 0;
      margin-right: 15px;

      span {
        display: block;
        size: 30px 3px;
        background-color: #00749e;

        &:not(:last-child) {
          margin-bottom: 7px;
        }
      }

      @include media('<xlarge') {
        display: inline-flex;
      }
    }

    > ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;
      display: flex;
      align-items: center;

      > li {
        position: relative;

        > a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 90px;
          padding: null 15px;
          font-size: 14px;
          font-weight: $weight-semibold;
          color: #0d5f7d;

          i {
            margin-left: 5px;
          }
        }

        ul {
          padding-left: 0;
          margin-bottom: 0;
          list-style-type: none;
          position: absolute;
          top: 100%;
          left: 0;
          z-index: $z-index-1;
          background-color: #2c6c88;
          width: 200px;
          display: none;

          li {
            a {
              display: block;
              padding: 10px 15px;
              color: $white;
              border-bottom: 1px solid rgba($white, 0.1);
              font-size: 14px;

              &:hover {
                background-color: rgba($white, 0.1);
              }
            }
          }
        }

        &:hover {
          > a {
            background-color: rgba(#2c6c88, 0.1);
          }
          ul {
            display: block;
          }
        }
      }

      @include media('<xlarge') {
        display: none;
      }
    }
  }

  &__searchbox {
    position: relative;
    margin-left: 15px;

    input {
      border: 1px solid rgba(#00749e, 0.5);
      border-radius: 10px;
      height: 50px;
      padding: null 15px;
      padding-right: 60px;
      font-family: $family-sans-serif-2;
      font-weight: $weight-medium;
      color: #0d5f7d;

      &:focus {
        outline-width: 0;
        border-color: darken(#0d5f7d, 10%);
      }
    }

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: transparent;
      size: 40px;
      border-width: 0;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);

      .w-icon {
        size: 100%;
      }
    }

    @include media('<medium') {
      display: none;
    }
  }
}
