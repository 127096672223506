// ::selection settings
$selection-background: rgba($color-2, 0.5);

// <body> settings
$body-background: #efefef !default;
$body-font-family: $family-sans-serif !default;
$body-font-size: 16px !default;
$body-font-weight: $weight-regular !default;
$body-text-color: $black !default;
$body-text-rendering: optimizeLegibility !default;
$body-line-height: 1.5 !default;

// <code> settings
$code-font-family: $family-sans-serif !default;

// <a> settings
$link-color: $color-3 !default;
$link-color-hover: darken($color-3, 15%) !default;
$link-color-active: darken($color-3, 25%) !default;

// <hr> settings
$hr-background-color: darken($white, 15%) !default;
$hr-height: 2px !default;
$hr-margin: 16px null !default;

// <strong> settings
$strong-weight: $weight-semibold !default;
